{
    class AflVaultFilterAddCriteriaButtonController {
        constructor() {
            console.log(this.filterCriteria);
        }
    }

    angular.module("adminApp")
        .component(
            'aflVaultFilterAddCriteriaButton', {
            controller: AflVaultFilterAddCriteriaButtonController,
            templateUrl: '/static/javascript/admin/afl-vault/afl-vault-filter-bar/afl-vault-filter-add-criteria-button/afl-vault-filter-add-criteria-button.html',
            bindings: {
                filterCriteria: '=',
                addedFilterCriteria: '=',
                selectFilterCriteria: '&'
            }
        });
}
